/* eslint-disable import/prefer-default-export */
// const SUBMIT_ANSWERS_HOOK = `https://hooks.zapier.com/hooks/catch/599828/o9vkqf5/`;
// const SUBMIT_USER_HOOK = `https://hooks.zapier.com/hooks/catch/599828/o5b8alo/`;
const GOOGLE_MAPS_API = `AIzaSyBCqiNyShGwMn7eQYvJC6NPc0JS9ihcjkg`;
const IP_URL = `https://freegeoip.app/json/`;
const BACKEND = process.env.REACT_APP_BACKEND;

export const getLocationData = async (latitude, longitude) => {
  const res = await fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?address=${latitude},${longitude}&key=${GOOGLE_MAPS_API}&result_type=administrative_area_level_1&language=en`
  );
  const json = await res.json();

  const provinceDetail = json.results[0].address_components.filter((addressComponent) =>
    addressComponent.types.includes('administrative_area_level_1')
  );

  const countryDetail = json.results[0].address_components.filter((addressComponent) =>
    addressComponent.types.includes('country')
  );

  const value = {
    provinceName: 'Not Available',
    countryName: 'Not Available',
    countryCode: 'Not Available',
  };

  if (provinceDetail && provinceDetail.length > 0 && provinceDetail[0].long_name) {
    value.provinceName = provinceDetail[0].long_name;
  }

  if (countryDetail && countryDetail.length > 0) {
    if (countryDetail[0].long_name) {
      value.countryName = countryDetail[0].long_name;
    }
    if (countryDetail[0].short_name) {
      value.countryCode = countryDetail[0].short_name;
    }
  }

  return value;
};

export const submitAnswers = async (answers) => {
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify(answers),
  };
  const res = await fetch(`${BACKEND}/symptoms`, requestOptions);
  const json = await res.json();
  return { result: json };
};

export const submitUser = async (user) => {
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify(user),
  };
  const res = await fetch(`${BACKEND}/contact-data`, requestOptions);
  const json = await res.json();
  return { result: json };
};

export const getCountryCode = async () => {
  const requestOptions = {
    method: 'GET',
  };
  const res = await fetch(IP_URL, requestOptions);
  const json = await res.json();
  return { ...json };
};
