import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Box from 'ui-box';
import { Row, Col, Button, Typography, Select } from 'antd';
import TextLoop from 'react-text-loop';
import Logo from '../../../components/common/Logo';
import useChangeLanguage from '../../../containers/hooks/useChangeLanguage';
import { ReactComponent as MedicineSvg } from '../../../assets/icons/medicine.svg';

const { Title } = Typography;

const styles = {
  title: {
    margin: 0,
  },
};

// const languagesMap = {
//   en: 'English',
//   dr: 'زبان مورد نظر من دری است',
//   ps: 'زما غوره ژبه پښتو ده',
// };

function MobileLanguageSelection() {
  const { t } = useTranslation(['common', 'welcome']);
  const history = useHistory();
  const { lang, langOptions, changeLanguage } = useChangeLanguage();

  const onChange = (value) => {
    const [language, direction] = value;
    changeLanguage(language, direction);
  };

  const onConfirm = () => {
    history.push('/welcome/location-access');
  };

  return (
    <>
      <Logo rootStyle={{ padding: 20 }} />
      <Box paddingBottom={12} paddingX={20}>
        <Row type="flex" align="middle" gutter={24} style={{ width: '100%' }}>
          <Col>
            <div className="language-label">
              <TextLoop>
                {langOptions.map(({ value }) => (
                  <Title key={value} level={4} style={styles.title}>
                    {t(`common:language.title.${value}`)}
                  </Title>
                ))}
              </TextLoop>
            </div>
          </Col>
          <Col>
            <div>
              <Select
                style={{ width: 180 }}
                size="large"
                value={t(`common:language.${lang}`)}
                onChange={onChange}
              >
                {langOptions.map(({ value, direction }) => (
                  <Select.Option key={value} value={[value, direction]}>
                    {t(`common:language.${value}`)}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </Col>
        </Row>
        <Box paddingTop={24} textAlign="center">
          <Row gutter={[0, 24]}>
            <Col xs={24}>
              <Title level={4} style={styles.title}>
                {t('welcome:getStarted.title')}
              </Title>
            </Col>

            <Col xs={24}>
              <MedicineSvg />
            </Col>
            <Col xs={24}>
              <Box backgroundColor="rgba(21, 169, 225, 0.05)" padding={12}>
                <Title level={4} style={styles.title}>
                  {t('welcome:getStarted.info')}
                </Title>
              </Box>
            </Col>
            <Col xs={24}>
              <Button block size="large" type="primary" onClick={onConfirm}>
                {t('common:button.getStarted')}
              </Button>
            </Col>
          </Row>
        </Box>
      </Box>
    </>
  );
}

export default MobileLanguageSelection;
