import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Box from 'ui-box';
import { Row, Col, Typography, Carousel, Button } from 'antd';
import MobileHeader from '../../../components/mobile/Header';
import { ReactComponent as DoctorMaleSvg } from '../../../assets/icons/doctor-male.svg';
// import { ReactComponent as DoctorFemaleSvg } from '../../../assets/icons/doctor-female.svg';

const { Title, Paragraph, Text } = Typography;

const styles = {
  carousel: {
    padding: 10,
    borderRadius: 4,
    backgroundColor: '#dcf2fb',
    overflow: 'hidden',
    fontSize: 16,
  },
  paragraph: {
    color: '#404047',
  },
};

const renderCarouselItem = (title, description) => (
  <Row>
    <Box textAlign="center" fontWeight={500}>
      <Paragraph style={styles.paragraph}>{title}</Paragraph>
    </Box>
    <Box textAlign="justify" fontSize={14} lineHeight="17px" marginBottom={18}>
      <Text>{description}</Text>
    </Box>
  </Row>
);

function Intro() {
  const { t } = useTranslation(['common', 'welcome']);
  const history = useHistory();

  const onStartScreening = () => {
    history.push('/questions');
  };

  return (
    <Row>
      <Col xs={24}>
        <MobileHeader />
        <Box paddingTop={16} paddingBottom={36} paddingX={20}>
          <Box marginBottom={24} textAlign="center">
            <DoctorMaleSvg />
          </Box>
          <Title level={2} className="text-center">
            {t('welcome:intro.title')}
          </Title>
          <Box paddingTop={10} paddingBottom={50}>
            <Carousel
              // autoplay
              style={styles.carousel}
            >
              {renderCarouselItem(
                t('welcome:intro.screening.title'),
                t('welcome:intro.screening.info')
              )}
              {renderCarouselItem(
                t('welcome:intro.outbreak.title'),
                t('welcome:intro.outbreak.info')
              )}
            </Carousel>
          </Box>
          <Button block size="large" type="primary" onClick={onStartScreening}>
            {t('common:button.startScreening')}
          </Button>
          <Box marginTop={24} textAlign="center" fontSize={14} color="#404047">
            <div>{t('common:forMoreInfoVisit')}</div>
            <div style={{direction: 'ltr'}}>
              <a
                href={`https://${t('common:alemHealthCovidUrl')}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('common:alemHealthCovidUrl')}
              </a>
            </div>
          </Box>
        </Box>
      </Col>
    </Row>
  );
}

export default Intro;
