import React from 'react';
import { Trans } from 'react-i18next';
import { Typography } from 'antd';

const { Paragraph } = Typography;

function Footer() {
  return (
    <Paragraph className="primary-heading-color text-center" style={{display: 'none'}}>
      <Trans i18nKey="viewMore">
        To view more data, view the{' '}
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a href="#" rel="noopener noreferrer" target="_blank">
          patient.alem.health
        </a>{' '}
        app
      </Trans>
    </Paragraph>
  );
}

export default Footer;
