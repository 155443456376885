import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FieldArray } from 'formik';
import Box from 'ui-box';
import SelectableItemBox from '../SelectableItemBox';
import { NONE } from './form-constants';

const getIndex = (arr, value) => (Array.isArray(arr) ? arr.indexOf(value) : -1);

const getDuplicateIndexOrValue = (formValue, optionValue) => {
  if (Array.isArray(formValue)) {
    return getIndex(formValue, optionValue);
  }
  return formValue;
};

const isSelected = ({ formValue, optionValue }) => {
  const duplicateIndexOrValue = getDuplicateIndexOrValue(formValue, optionValue);

  if (typeof duplicateIndexOrValue === 'number') {
    return duplicateIndexOrValue >= 0;
  }

  return duplicateIndexOrValue === optionValue;
};

const MobileControlledOptionsFieldArray = ({
  fieldName,
  options,
  isMultiSelect,
  boxWidth,
}) => {
  const { t } = useTranslation();

  return (
    <FieldArray
      name={fieldName}
      render={({ push, remove, replace, form }) => {
        // console.log(form.values);
        return (
          <>
            {options.map(({ value, label: l }) => {
              const label = t(`${l}`);

              const selected = isSelected({
                formValue: form.values[fieldName],
                optionValue: value,
              });

              const onSelect = () => {
                if (isMultiSelect || Array.isArray(form.values[fieldName])) {
                  if (value === NONE) {
                    form.setFieldValue(fieldName, [value]);
                    return;
                  }

                  if (isMultiSelect && selected) {
                    const duplicateIndex = getDuplicateIndexOrValue(
                      form.values[fieldName],
                      value
                    );
                    remove(duplicateIndex);
                  } else if (isMultiSelect && !selected) {
                    push(value);
                  } else {
                    replace(0, value);
                  }
                } else {
                  form.setFieldValue(fieldName, value);
                }

                const noneIndex = getIndex(form.values[fieldName], NONE);

                if (noneIndex > -1) {
                  remove(noneIndex);
                }
              };

              return (
                <Box
                  key={value}
                  paddingBottom={24}
                  maxWidth={boxWidth}
                  marginY={0}
                  marginX="auto"
                >
                  <SelectableItemBox
                    isSelected={selected}
                    isMultiSelect={isMultiSelect}
                    isTextCentred
                    onClick={onSelect}
                  >
                    {label}
                  </SelectableItemBox>
                </Box>
              );
            })}
          </>
        );
      }}
    />
  );
};

const { string, oneOfType, arrayOf, bool, shape } = PropTypes;

MobileControlledOptionsFieldArray.defaultProps = {
  isMultiSelect: false,
  options: [],
  boxWidth: '225px',
};

MobileControlledOptionsFieldArray.propTypes = {
  fieldName: string.isRequired,
  isMultiSelect: bool,
  options: arrayOf(
    shape({
      value: oneOfType([string, bool]).isRequired,
      label: string.isRequired,
    })
  ),
  boxWidth: string,
};

export default MobileControlledOptionsFieldArray;
