import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { Form } from 'antd';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

function PhoneInputField(props) {
  const { name, label, placeholder, formItemLayout } = props;
  // eslint-disable-next-line
  const [field, meta, helpers] = useField(name);

  const onChange = (e) => {
    // if (e.target.value && e.target.value.length > 12) {
    //   e.target.value = e.target.value.slice(0, 13);
    // }
    field.onChange(e);
  };
  return (
    <Form.Item {...formItemLayout} label={label} help={meta.touched && meta.error}>
      <PhoneInput
        inputClass="ant-input ant-input-lg"
        country="af"
        preferredCountries={['us', 'af']}
        // onlyCountries={['af']}
        placeholder={placeholder}
        // disableCountryCode
        value={field.value}
        inputProps={{
          name: field.name,
          onChange,
          onBlur: field.onBlur,
        }}
      />
    </Form.Item>
  );
}

PhoneInputField.defaultProps = {
  formItemLayout: {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  },
};

const { shape, string } = PropTypes;

PhoneInputField.propTypes = {
  name: string.isRequired,
  label: string,
  placeholder: string,
  formItemLayout: shape({}),
};

export default PhoneInputField;
