import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Box from 'ui-box';
import AntdAlertErrorBoundary from '../../components/common/AntdAlertErrorBoundary';
import MobileHeader from '../../components/mobile/Header';
import GreenCategory from  '../../pages/mobile/recommended-medical-care/GreenCategory';
import AmberCategory from  '../../pages/mobile/recommended-medical-care/AmberCategory';
import YellowCategory from  '../../pages/mobile/recommended-medical-care/YellowCategory';
import RedCategory from '../../pages/mobile/recommended-medical-care/RedCategory';

function MobileRecommendedLayout() {

  return (
    < >
      <Box position="relative">
        <AntdAlertErrorBoundary>
          <MobileHeader />
        </AntdAlertErrorBoundary>
        <AntdAlertErrorBoundary>
          <Box paddingTop={28} paddingBottom={36} paddingX={20}>
            <Switch>
              <Route path="/recommended-medical-care/green-category">
                <GreenCategory />
              </Route>
              <Route path="/recommended-medical-care/orange-category">
                <AmberCategory />
              </Route>
              <Route path="/recommended-medical-care/yellow-category">
                <YellowCategory />
              </Route>
              <Route path="/recommended-medical-care/red-category">
                <RedCategory />
              </Route>
              <Redirect to="/questions" />
            </Switch>
          </Box>
        </AntdAlertErrorBoundary>
      </Box>
    </>
  );
}

export default MobileRecommendedLayout;
