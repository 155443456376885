/* eslint-disable no-nested-ternary */
const createStorage = (provider) => ({
  get(key, initialState) {
    const json = provider.get(key);
    return json || initialState
  },
  set(key, value) {
    provider.set(key, value);
  },
});

export default createStorage;
