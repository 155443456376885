import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Box from 'ui-box';
import AntdAlertErrorBoundary from '../../components/common/AntdAlertErrorBoundary';
import MobileHeader from '../../components/mobile/Header';
import useUUID from '../../containers/hooks/useUUID'
import history from '../../routes/history';
import GreenCategory from '../../pages/mobile/patient-data/GreenCategory'
import AmberCategory from '../../pages/mobile/patient-data/AmberCategory'
import RedCategory from '../../pages/mobile/patient-data/RedCategory'
import YellowCategory from '../../pages/mobile/patient-data/YellowCategory'

function MobilePatientDataLayout() {
  const { uuid } = useUUID();

  useEffect(()=>{
    if(uuid === ''){
      history.push('/')
    }
  },[uuid])

  return (
    < >
      <Box position="relative">
        <AntdAlertErrorBoundary>
          <MobileHeader />
        </AntdAlertErrorBoundary>
        <AntdAlertErrorBoundary>
          <Box paddingTop={28} paddingBottom={36} paddingX={20}>
            <Switch>
              <Route path="/patient-data/green-category">
                <GreenCategory />
              </Route>
              <Route path="/patient-data/orange-category">
                <AmberCategory />
              </Route>
              <Route path="/patient-data/red-category">
                <RedCategory />
              </Route>
              <Route path="/patient-data/yellow-category">
                <YellowCategory />
              </Route>
              <Redirect to="/questions" />
            </Switch>
          </Box>
        </AntdAlertErrorBoundary>
      </Box>
    </>
  );
}

export default MobilePatientDataLayout;
