import React from 'react';
// import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Box from 'ui-box';
import { Row, Col, Typography } from 'antd';
// import SingUpForm from '../../../components/mobile/SignUpForm';
import { ReactComponent as GreenDoctor } from '../../../assets/icons/green-doctor.svg';
import RecommendedCategoryAlert from '../../../components/mobile/RecommendedCategoryAlert';
import RecommendedSocialShare from '../../../components/mobile/RecommendedSocialShare';
import Footer from './Footer';

const { Title, Paragraph } = Typography;

const styles = {
  col: {
    display: 'flex',
  },
};

function GreenCategory() {
  const { t } = useTranslation('recommended');
  // const history = useHistory()

  return (
    <>
      <Row justify="center">
        <Col style={styles.col}>
          <GreenDoctor />
        </Col>
      </Row>
      <Box paddingTop={28} textAlign="center">
        {/* <Title level={4}>{t('thankYou')}</Title> */}
        <Box>
          <Title level={4}>{t('greenCategory.symptoms')}</Title>
        </Box>
      </Box>
      <Box paddingTop={19}>
        <RecommendedSocialShare />
      </Box>
      <Box paddingTop={28}>
        <RecommendedCategoryAlert
          type="success"
          message={t('greenCategory.followTheGuidance')}
        />
      </Box>
      <Box paddingTop={46} paddingBottom={30} textAlign="justify">
        <Paragraph className="primary-heading-color">
          {t('greenCategory.ifExperienceAnyOfTheSymptoms')}
        </Paragraph>
      </Box>
      <Footer />
      {/*
      // <Box paddingBottom={25} textAlign="justify">
      //   <Paragraph style={styles.paragraph}>
      //     {t('greenCategory.ifExperienceAnyOfTheSymptoms')}
      //     <br />
      //     <br />
      //     {t('greenCategory.signUpHereToReceiveInformation')}
      //   </Paragraph>
      // </Box> */}

      {/*
      {hideForm ? null : <SingUpForm
        onSubmit={onSubmitGreenCategory}
        onSubmitBtnText={t('common:button.signUp')}
      />} */}
    </>
  );
}

export default GreenCategory;
