/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-console */
import React from 'react';
import {
  useRouteMatch,
  Switch,
  useHistory,
  Route,
  Redirect,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import Box from 'ui-box';
import { Persist } from 'formik-persist';
import {
  getMobileQuestionRoutes,
  getMobileQuestionFromYesAnswerRoutes,
} from '../../routes/mobile/questionRoutes';
import LoadingSpinner from '../../components/common/LoadingSpinner';
import AntdAlertErrorBoundary from '../../components/common/AntdAlertErrorBoundary';
import MobileHeader from '../../components/mobile/Header';
import QuestionsNavBtn from '../../components/mobile/form-helpers/QuestionsNavBtn';
import useLocation from '../../containers/hooks/useLocation';
import useUUID from '../../containers/hooks/useUUID';
import useChangeLanguage from '../../containers/hooks/useChangeLanguage';
import useScreeningData from '../../containers/hooks/useScreeningData';
import { submitAnswers } from '../../api';
import {
  NONE,
  DIFFICULTY_BREATHING,
  RED,
  ORANGE,
  YELLOW,
  GREEN,

} from '../../components/mobile/form-helpers/form-constants';
import { errorNotification } from '../../utils/notification-helpers';


const getCareLevel = (answers) => {
  const {
    age,
    symptoms,
    additionalSymptoms,
    hadAFeverInTheLast14Days,
    hadAFeverDays,
    hadAContinuousCoughInTheLast14Days,
    hadAContinuousCoughDays,
    hadALossInTasteAndSmellInTheLast14Days,
    hadALossInTasteAndSmellDays,
    beenDiagnosedWithConditions,
    haveTheFollowing,
    isApplyToYou,
    emergencySymptoms
  } = answers;

  let emergencySymptomsCount = emergencySymptoms && emergencySymptoms[0] !== NONE ? emergencySymptoms.length : 0;

  if(emergencySymptoms && emergencySymptoms === NONE){
    emergencySymptomsCount = 0
  }

  const symptomsCount = symptoms && symptoms[0] !== NONE ? symptoms.length : 0;
  const additionalSymptomsCount = additionalSymptoms && additionalSymptoms[0] !== NONE ? additionalSymptoms.length : 0;
  const allSymptomsCount = symptomsCount + additionalSymptomsCount;
  const feverDaysCount = hadAFeverInTheLast14Days===true ? hadAFeverDays : 0;
  const coughDaysCount = hadAContinuousCoughInTheLast14Days===true ? hadAContinuousCoughDays : 0;
  const lossInTasteAndSmellDaysCount = hadALossInTasteAndSmellInTheLast14Days===true ? hadALossInTasteAndSmellDays : 0;

  const beenDiagnosedWithConditionsCount = beenDiagnosedWithConditions && beenDiagnosedWithConditions[0] !== NONE ? beenDiagnosedWithConditions.length : 0;
  const haveTheFollowingCount = haveTheFollowing && haveTheFollowing[0] !== NONE ? haveTheFollowing.length : 0;
  const isApplyToYouCount = isApplyToYou && isApplyToYou[0] !== NONE ? isApplyToYou.length : 0;


  // RED CATEGORY LOGIC
  if(emergencySymptomsCount > 0 || symptoms.includes(DIFFICULTY_BREATHING || emergencySymptomsCount > 0)){
      return RED;
  }

  if((allSymptomsCount>=1 || (feverDaysCount>0||coughDaysCount>0||lossInTasteAndSmellDaysCount>0))&&(age>=70 || (beenDiagnosedWithConditionsCount > 0 || haveTheFollowingCount > 0 || isApplyToYouCount > 0 ))){
      return RED;
  }

  // GREEN CATEGORY LOGIC
  if(age < 70 && allSymptomsCount === 0 && (feverDaysCount  === 0 && coughDaysCount  === 0 && lossInTasteAndSmellDaysCount === 0)){
    return GREEN;
  }

  // YELLOW CATEGORY LOGIC
  if(age < 70 && allSymptomsCount >= 1 && (beenDiagnosedWithConditionsCount === 0 || haveTheFollowingCount === 0 || isApplyToYouCount  ===  0 ) && (feverDaysCount < 5 && coughDaysCount  < 5 && lossInTasteAndSmellDaysCount < 5)){
    return YELLOW;
  }

  // ORANGE CATEGORY LOGIC DEFAULT
    return ORANGE;

};



const QuestionWrapper = ({ title, children, ...restNavBtnProps }) => (
  <>
    <Box textAlign="center" fontWeight={500} color="#15A9E1">
      {title}
    </Box>
    {children}
    <QuestionsNavBtn {...restNavBtnProps} />
  </>
);

function MobileQuestionsLayout() {
  const { t } = useTranslation(['common', 'questions']);
  const match = useRouteMatch();
  const history = useHistory();
  // console.log('match', match);
  const { path } = match;
  // const location = useLocation();
  // console.log('location', location);
  const { location } = useLocation();
  const { uuid } = useUUID();
  const { setScreeningData } = useScreeningData();
  const { lang, langOptions } = useChangeLanguage();

  const initialValues = {
    forWhomAnswering: '',
    age: 30,
    gender: '',
    family: '',
    isWorkInHealthcareFacility: '',
    symptoms: [],
    additionalSymptoms: [],
    isExposedFeverOrCoughPast14Days: '',
    hadAFeverInTheLast14Days: '',
    hadAFeverDays: 1,
    hadAContinuousCoughInTheLast14Days: '',
    hadAContinuousCoughDays: 1,
    hadALossInTasteAndSmellInTheLast14Days: '',
    hadALossInTasteAndSmellDays: 1,
    beenDiagnosedWithConditions: [],
    haveTheFollowing: [],
    isApplyToYou: [],
    emergencySymptoms:[]
  };

  const validateScreeningData = (values) => {

    const {patientId, careLevel, forWhomAnswering, gender, family, isWorkInHealthcareFacility, isExposedFeverOrCoughPast14Days, hadAFeverInTheLast14Days, hadAContinuousCoughInTheLast14Days, hadALossInTasteAndSmellInTheLast14Days } = values

    if(forWhomAnswering === undefined || forWhomAnswering === ''){
      return false
    }

    if(gender === undefined || gender ===''){
      return false
    }

    if(family === undefined || family ===''){
      return false
    }

    if(isWorkInHealthcareFacility === undefined || isWorkInHealthcareFacility  ===''){
      return false
    }

    if(isExposedFeverOrCoughPast14Days === undefined || isExposedFeverOrCoughPast14Days === ''){
      return false
    }

    if(hadAFeverInTheLast14Days === undefined || hadAFeverInTheLast14Days ===''){
      return false
    }

    if(hadAContinuousCoughInTheLast14Days === undefined || hadAContinuousCoughInTheLast14Days ===''){
      return false
    }

    if(hadALossInTasteAndSmellInTheLast14Days === undefined || hadALossInTasteAndSmellInTheLast14Days ===''){
      return false
    }

    if(patientId === undefined || patientId === ''){
      return false
    }

    if(careLevel === undefined || careLevel === ''){
      return false
    }

    return true;
  }

  const renderRoutes = (routes, total = routes.length, gender) =>
    routes.map(({ id: count, path: p, component: Component, props: componentProps }) => {
      const {
        prevRoutePath,
        nextRoutePath,
        additionalRoute,
        recommendedRoute,
        options,
        isMultiSelect,
        ...restComponentProps
      } = componentProps;

      let updatedOptions = options;

      if (componentProps.fieldName === 'isApplyToYou' && gender === 'male') {
        updatedOptions = [...options];
        updatedOptions.shift();
      }

      return (
        <Route key={p} path={p}>
          <QuestionWrapper
            title={count>0 ? t('questions:questionsCount', { count, total }) : t('questions:emergencySymptoms.title') }
            fieldName={componentProps.fieldName}
            isLastQuestion={componentProps.isLastQuestion}
            prevRoutePath={prevRoutePath}
            nextRoutePath={nextRoutePath}
            additionalRoute={additionalRoute}
            recommendedRoute={recommendedRoute}
          >
            <Component options={updatedOptions} isMultiSelect={isMultiSelect} {...restComponentProps} />
          </QuestionWrapper>
        </Route>
      );
    });
  return (
    < >
      <Box position="relative">
        <AntdAlertErrorBoundary>
          <MobileHeader />
        </AntdAlertErrorBoundary>
        <AntdAlertErrorBoundary>
          <Formik
            initialValues={initialValues}
            onSubmit={async (values, { setSubmitting, resetForm }) => {

              let { emergencySymptoms } = values
              if(!Array.isArray(emergencySymptoms)){
                emergencySymptoms = [emergencySymptoms]
              }
              const answers = { ...values, location, emergencySymptoms };
              const careLevel = getCareLevel(answers);
              const language = langOptions.filter(
                (langoption) => langoption.value === lang
              );
              if (answers.hadAContinuousCoughInTheLast14Days !== true) {
                answers.hadAContinuousCoughDays = 0;
              }
              if (answers.hadAFeverInTheLast14Days !== true) {
                answers.hadAFeverDays = 0;
              }
              if (answers.hadAFeverInTheLast14Days !== true) {
                answers.hadALossInTasteAndSmellDays = 0;
              }
              answers.careLevel = careLevel;
              answers.language = language[0].label;
              answers.patientId = uuid;

              const isValid = validateScreeningData(answers)

              if(!isValid){
                errorNotification(t('common:form.error.submit'));
                setSubmitting(false);
                resetForm({ values: initialValues });
                history.push(`/`)
              } else {
                try {
                  await submitAnswers(answers);
                  setScreeningData(answers);
                  setSubmitting(false);
                  resetForm({ values: initialValues });
                  history.push(`/patient-data/${careLevel}-category`);
                } catch (err) {
                  console.error(err)
                  errorNotification(t('common:form.error.submit'));
                  setSubmitting(false);
                  resetForm({ values: initialValues });
                  history.push(`/`)
                }
              }
            }}
          >
            {({ isSubmitting, values }) => (
              <Form loading={isSubmitting.toString()}>
                <Box paddingTop={20} paddingBottom={36} paddingX={20}>
                  {isSubmitting}
                  <Switch>
                    {renderRoutes(
                      getMobileQuestionRoutes(),
                      getMobileQuestionRoutes().length-1,
                      values.gender
                    )}
                    {renderRoutes(
                      getMobileQuestionFromYesAnswerRoutes(),
                      getMobileQuestionRoutes().length-1
                    )}
                    <Redirect to={`${path}/emergency-symptoms`} />
                  </Switch>
                </Box>
                <Persist name="screening-form" />
                {isSubmitting ? (
                  <LoadingSpinner position="full" transparent={false} />
                ) : null}
              </Form>
            )}
          </Formik>
        </AntdAlertErrorBoundary>
      </Box>
    </>
  );
}

export default MobileQuestionsLayout;
