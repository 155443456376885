import React from 'react';
// import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import Box from 'ui-box';
import { Typography, Button, Row, Col } from 'antd';
import iconFacebook from '../../assets/icons/icon-fb.png';
import iconMessenger from '../../assets/icons/icon-messenger.png';
import iconWhatsapp from '../../assets/icons/icon-whatsapp.png';

const { Paragraph } = Typography;

const styles = {
  paragraph: {
    marginBottom: 0,
  },
  btn: {
    whiteSpace: 'break-spaces',
    lineHeight: 'initial',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px',
  },
};

function RecommendedSocialShare() {
  const { t } = useTranslation('recommended');

  return (
    <Box padding={10} borderRadius={4} backgroundColor="rgba(21, 169, 225, 0.05)" id="share-button">
      <Paragraph style={styles.paragraph} className="text-justify primary-heading-color">
        {t('socialShare.message')}
      </Paragraph>
      <Box>
        <Row align="middle" justify="center">
          <Col>
            <a
              className="icon-social-share"
              href={t('socialShare.facebookLink')}
              target="__blank"
            >
              <img src={iconFacebook} alt="facebook icon" />
            </a>
          </Col>
          <Col>
            <a
              className="icon-social-share"
              href={t('socialShare.whatsappLink')}
              target="__blank"
            >
              <img src={iconWhatsapp} alt="whatsapp icon" />
            </a>
          </Col>
          <Col>
            <a
              className="icon-social-share"
              href={t('socialShare.messengerLink')}
              target="__blank"
            >
              <img src={iconMessenger} alt="messenger icon" />
            </a>
          </Col>
        </Row>
        <Button
          type="primary"
          block
          size="large"
          href="/"
          style={styles.btn}
          className="text-capitalize"
        >
          {t('socialShare.clickHere')}
        </Button>
      </Box>
    </Box>
  );
}

export default RecommendedSocialShare;
