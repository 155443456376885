import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './styles/mobile/global.scss';

import './i18n';

// import registerServiceWorker from './registerServiceWorker'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://cd7923472f254e2895dee162efed7de1@o373641.ingest.sentry.io/5230726',
    integrations: [
      new Integrations.CaptureConsole({
        levels: ['error'],
      }),
    ],
  });
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
