import { SLACK_WEB_HOOK, SLACK_APP_NAME, SLACK_APP_TYPE, allowSlackNotifications } from '../config/constants'

const prepareNotificationPayload = (title, description, jsonContent) => {
  const notificationMessage = `>>> [${jsonContent.careLevel.toUpperCase()}] [Patient: ${jsonContent.phone}] - ${title}`
  // notificationMessage += '\n'
  // notificationMessage += '\n'
  // if (description) {
  //   notificationMessage += '\n'
  //   notificationMessage += description
  // }
  // if (jsonContent) {
  //   Object.keys(jsonContent).forEach((key) => {
  //     notificationMessage += '\n'
  //     if(key==='location'){
  //       notificationMessage += `${key  }: ${  JSON.stringify(jsonContent[key])}`
  //     }else{
  //       notificationMessage += `${key  }: ${  jsonContent[key]}`
  //     }
  //   })
  // }

  return {
    text: notificationMessage,
    username: `${SLACK_APP_NAME  } [ ${  SLACK_APP_TYPE  } ]`
  }
}

// eslint-disable-next-line import/prefer-default-export
export const postNotificationToSlackAPICall = async ({ title, jsonContent = null, description = null }) => {
  try {
    if (!allowSlackNotifications) {
      return false
    }

    if(jsonContent && jsonContent.careLevel){
      const payload = prepareNotificationPayload(title, description, jsonContent);

      const config = {
        method: 'POST',
        body: JSON.stringify(payload)
      }
      const slackResponse = await window.fetch(SLACK_WEB_HOOK, config)
      const responseOkBySlack = await slackResponse.ok

      return { responseOk: responseOkBySlack }
    }
    return false
  } catch (e) {
    console.log('ERROR: ', e)
    return false;
  }
}
