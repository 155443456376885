import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Box from 'ui-box';
import { Typography } from 'antd';
import SingUpForm from '../../../components/mobile/SignUpForm';

const { Title } = Typography;

function GreenCategory() {
  const { t } = useTranslation('recommended');
  const history = useHistory();

  const onSubmitAmberCategory = () => {
    history.push(`/recommended-medical-care/green-category`);
  };

  return (
    <>
      <Box paddingTop={25} textAlign="center">
        <Title level={4}>{t('thankYou')}</Title>
        <Box paddingTop={15}>
          <Title level={4}>{t('preparingGuidance')}</Title>
        </Box>
      </Box>
      <SingUpForm
        onSubmit={onSubmitAmberCategory}
        onSubmitBtnText={t('common:button.confirm')}
      />
    </>
  );
}

export default GreenCategory;
