import appReducer, { initialState as appInitialState } from './app/reducers';

export const initialRootState = {
  app: appInitialState,
};

const rootReducer = (state, action) => ({
  app: appReducer(state.app, action),
});

export default rootReducer;
