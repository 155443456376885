import React, { Suspense, useEffect, useState } from 'react';
import { ConfigProvider } from 'antd';
import i18next from 'i18next';
// import moment from 'moment';
// import enUS from 'antd/es/locale/en_US';
// import zhCN from 'antd/es/locale/zh_CN';
// import arEG from 'antd/es/locale/ar_EG';
// import 'moment/locale/zh-cn';
import createPersistedReducer from './utils/persisted-store/index';
import AntdAlertErrorBoundary from './components/common/AntdAlertErrorBoundary';
import LoadingSpinner from './components/common/LoadingSpinner';
import AppDispatchContext from './context/app-dispatch-context';
import AppStateContext from './context/app-state-context';
import rootReducer, { initialRootState } from './state';
// import { isMobile } from './utils/device-helpers';
import MobileRoutesContainer from './containers/mobile/RoutesContainer';
// import DesktopRoutesContainer from './containers/desktop/RoutesContainer';

const init = (initialState) => ({
  ...initialState,
});

function App() {
  const usePersistedReducer = createPersistedReducer('state');

  const [state, dispatch] = usePersistedReducer(rootReducer, initialRootState, init);

  const {
    app: { /* lang, */ direction = 'ltr' },
  } = state || {app:null};

  const [language, changeLanguage] = useState('en');
  useEffect(() => {
    i18next.on('languageChanged', (/* lng */) => {
      // console.log('languageChanged', lng);
      // moment.locale(lng);
    });
    const url = window.location.href;
    const regex = new RegExp('[?&]lang(=([^&#]*)|&|#|$)');
    const results = regex.exec(url);
    if (results && results[2]) {
      changeLanguage(results[2]);
    }
  }, []);

  return (
    <Suspense fallback={<LoadingSpinner position="full" />}>
      <AntdAlertErrorBoundary>
        <AppDispatchContext.Provider value={dispatch}>
          <AppStateContext.Provider value={state}>
            <ConfigProvider /* locale={} */ direction={direction}>
              <MobileRoutesContainer language={language} />
            </ConfigProvider>
          </AppStateContext.Provider>
        </AppDispatchContext.Provider>
      </AntdAlertErrorBoundary>
    </Suspense>
  );
}

export default App;
