/* eslint-disable import/no-cycle */
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { getMobileChildWelcomeRoutes } from '../../routes/mobile/welcomeRoutes';
import AntdAlertErrorBoundary from '../../components/common/AntdAlertErrorBoundary';

function MobileWelcomeLayout() {
  return (
    < >
      <AntdAlertErrorBoundary>
        <Switch>
          {getMobileChildWelcomeRoutes().map(({ path: p, component: Component }) => (
            <Route key={p} path={p}>
              <Component />
            </Route>
          ))}
          <Redirect to="/welcome/language-selection" />
        </Switch>
      </AntdAlertErrorBoundary>
    </>
  );
}

export default MobileWelcomeLayout;
