/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import history from '../../routes/history';
import MobileWelcomeLayout from '../../layouts/mobile/WelcomeLayout';
import MobileQuestionsLayout from '../../layouts/mobile/QuestionsLayout';
import MobileRecommendedLayout from '../../layouts/mobile/RecommendedLayout';
import MobilePatientDataLayout from '../../layouts/mobile/PatientDataLayout';
import useChangeLanguage from '../hooks/useChangeLanguage';
import { getCountryCode } from '../../api/index';

function MobileRoutesContainer({ language }) {
  const { langOptions, changeLanguage } = useChangeLanguage();

  useEffect(() => {
    async function getCountryCodeCall() {
      const result = await getCountryCode();
      const countryCode = result.country_code.toLowerCase();
      if (result && result.country_code) {
        const selectedLang = langOptions.filter(
          (langoption) => langoption.value === countryCode
        );
        if (selectedLang && selectedLang[0] && selectedLang[0].label) {
          changeLanguage(countryCode, selectedLang[0].direction);
        }
      }
    }
    const selectedLang = langOptions.filter(
      (langoption) => langoption.value === language
    );
    if (selectedLang && selectedLang[0] && selectedLang[0].label) {
      changeLanguage(language, selectedLang[0].direction);
    } else {
      getCountryCodeCall();
    }
  }, []);

  return (
    <div className="mobile-container">
      <div id="mobile-device">
        <Router history={history}>
          <Switch>
            <Route path="/welcome">
              <MobileWelcomeLayout />
            </Route>
            <Route path="/questions">
              <MobileQuestionsLayout />
            </Route>
            <Route path="/recommended-medical-care">
              <MobileRecommendedLayout />
            </Route>
            <Route path="/patient-data">
              <MobilePatientDataLayout />
            </Route>
            <Redirect to="/welcome" />
          </Switch>
        </Router>
      </div>
    </div>
  );
}

MobileRoutesContainer.defaultProps = {
  language: 'en',
};

MobileRoutesContainer.propTypes = {
  language: PropTypes.string,
};

export default MobileRoutesContainer;
