import { useContext } from 'react';
import AppDispatchContext from '../../context/app-dispatch-context';
import { setLocation } from '../../state/app/actions';
import AppStateContext from '../../context/app-state-context';

function useLocation() {
  const state = useContext(AppStateContext);
  const dispatch = useContext(AppDispatchContext);

  const {
    app: { location },
  } = state;

  return {
    location,
    setLocation: (loc) => dispatch(setLocation(loc)),
  };
}

export default useLocation;
