/* eslint-disable import/no-cycle */
import React from 'react';
import WelcomeLayout from '../../layouts/mobile/WelcomeLayout'
import LanguageSelection from '../../pages/mobile/welcome/LanguageSelection'
import LocationAccess from '../../pages/mobile/welcome/LocationAccess'
import Disclaimer from '../../pages/mobile/welcome/Disclaimer'
import Intro from '../../pages/mobile/welcome/Intro'

const mobileWelcomeRoutes = [
  {
    path: '/welcome',
    parentPath: null,
    name: 'Welcome',
    component: () => <WelcomeLayout />,
  },
  {
    path: '/welcome/language-selection',
    parentPath: '/welcome',
    name: 'Language selection',
    component: () => <LanguageSelection />,
  },
  {
    path: '/welcome/location-access',
    parentPath: '/welcome',
    name: 'Location access',
    component: () => <LocationAccess />,
  },
  {
    path: '/welcome/disclaimer',
    parentPath: '/welcome',
    name: 'Disclaimer',
    component: () => <Disclaimer />
  },
  {
    path: '/welcome/introduction',
    parentPath: '/welcome',
    name: 'Introduction',
    component: () => <Intro />,
  },
];

// eslint-disable-next-line import/prefer-default-export
export const getMobileChildWelcomeRoutes = () =>
  mobileWelcomeRoutes.filter(({ parentPath }) => parentPath);
