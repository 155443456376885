import createReducer from '../utils/createReducer';
import { SET_LANGUAGE, SET_LOCATION, SET_ANSWERS, SET_UUID, SET_SCREENING_DATA, SET_APP_DATA } from './types';

export const initialState = {
  langOptions: [
    {
      value: 'en',
      direction: 'ltr',
      label: 'English',
    },
    {
      value: 'dr',
      direction: 'rtl',
      label: 'Dari',
    },
    {
      value: 'ps',
      direction: 'rtl',
      label: 'Pashto',
    },
  ],
  lang: 'en',
  direction: 'ltr', // ltr | rtl
  location: {},
  answers: {},
  uuid: '',
  screeningData: {}
};

const appReducer = createReducer(initialState)({
  [SET_LANGUAGE]: (state, { lang, direction = 'ltr' }) => {
    return { ...state, lang, direction };
  },
  [SET_LOCATION]: (state, { location }) => ({ ...state, location }),
  [SET_ANSWERS]: (state, { answers }) => ({ ...state, answers }),
  [SET_UUID]: (state, { uuid }) => ({ ...state, uuid }),
  [SET_SCREENING_DATA]: (state, { screeningData }) => ({ ...state, screeningData }),
  [SET_APP_DATA]: (state, {appData}) => {
    return { ...appData }
  },
});

export default appReducer;
