import React from 'react';
import PropTypes from 'prop-types';
// import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import Box from 'ui-box';
import { Typography, Button } from 'antd';

const { Paragraph } = Typography;

const styles = {
  paragraph: {
    color: '#404047',
    fontWeight: 500,
    textAlign: 'justify',
    marginBottom: 0,
  },
};

const statusColorMap = {
  success: '#ECFBEE',
  warning: '#FDF6E8',
  info: '#FCFAEA',
  error: '#FBF3F3',
};

function RecommendedCategoryAlert({ type, message, showGuideLinks }) {
  const { t } = useTranslation('recommended');

  return (
    <Box padding={10} borderRadius={4} backgroundColor={statusColorMap[type]}>
      <Paragraph style={styles.paragraph}>{message}</Paragraph>
      {showGuideLinks && (
        <Box marginTop={35}>
          <Box paddingBottom={24}>
            <Button
              type="primary"
              block
              size="large"
              href={t('guideToHandWashingUrl')}
              target="__blank"
            >
              {t('guideToHandWashing')}
            </Button>
          </Box>
          <Box paddingBottom={24}>
            <Button
              type="primary"
              block
              size="large"
              href={t('guideToSocialDistancingUrl')}
              target="__blank"
            >
              {t('guideToSocialDistancing')}
            </Button>
          </Box>
          <Box>
            <Button
              type="primary"
              block
              size="large"
              href={t('guideToCOVID19SymptomsUrl')}
              target="__blank"
            >
              {t('guideToCOVID19Symptoms')}
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
}

RecommendedCategoryAlert.defaultProps = {
  showGuideLinks: true,
};

const { string, oneOf, bool } = PropTypes;

RecommendedCategoryAlert.propTypes = {
  type: oneOf(['success', 'warning', 'info', 'error']),
  message: string,
  showGuideLinks: bool,
};

export default RecommendedCategoryAlert;
