import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import AppStateContext from '../../context/app-state-context';
import AppDispatchContext from '../../context/app-dispatch-context';
import { setLanguage } from '../../state/app/actions';

function useChangeLanguage() {
  const { i18n } = useTranslation();

  const state = useContext(AppStateContext);
  const dispatch = useContext(AppDispatchContext);

  const {
    app: { langOptions, lang, direction },
  } = state;

  const changeLanguage = (language, dir) => {
    i18n.changeLanguage(language);

    if (language) {
      dispatch(setLanguage(language, dir));
    }
  };

  return {
    langOptions,
    lang,
    direction,
    changeLanguage,
  };
}

export default useChangeLanguage;
