/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { ReactComponent as FamilySvg } from '../../assets/icons/family.svg';
import { ReactComponent as HealthWorkersSvg } from '../../assets/icons/health-workers.svg';
import { ReactComponent as FeverSvg } from '../../assets/icons/fever.svg';
import { ReactComponent as CoughingSvg } from '../../assets/icons/coughing.svg';
import { ReactComponent as DaysSvg } from '../../assets/icons/days.svg';
import CommonTemplate from '../../pages/mobile/questions/CommonTemplate'
import SliderTemplate from '../../pages/mobile/questions/SliderTemplate'
import RadioBtnTemplate from '../../pages/mobile/questions/RadioBtnTemplate'
import Family from '../../pages/mobile/questions/Family'

const singleAnswerOptions = [
  {
    value: true,
    label: 'common:button.yes',
  },
  {
    value: false,
    label: 'common:button.no',
  },
  {
    value: `I don't know`,
    label: 'questions:iDoNotKnow',
  },
];

const mobileQuestionRoutes = [
  {
    id: '0',
    title: 'questions:emergencySymptoms.title',
    path: '/questions/emergency-symptoms',
    component: (props) => <CommonTemplate {...props} />,
    props: {
      fieldName: 'emergencySymptoms',
      prevRoutePath: '/welcome/introduction',
      nextRoutePath: '/questions/for-whom-answering',
      question: 'questions:emergencySymptoms.question',
      hint: 'questions:hint',
      isMultiSelect: true,
      options: [
        {
          value: 'Shortness of breath that severely limits your ability to breathe or speak',
          label: 'questions:emergencySymptoms.symptoms.shortnessOfBreath',
        },
        {
          value: 'Persistent pain or pressure in the chest that severely reduces your ability to perform activities like walking up stairs',
          label: 'questions:emergencySymptoms.symptoms.persistentPainOrPressure',
        },
        {
          value: 'Reduced ability to stay awake',
          label: 'questions:emergencySymptoms.symptoms.reducedAbilityToStayAwake',
        },
        {
          value: 'Confusion that severely reduces your ability to perform simple tasks.',
          label: 'questions:emergencySymptoms.symptoms.confusion',
        },
        {
          value: 'Your lips or face appear blue in color',
          label: 'questions:emergencySymptoms.symptoms.appearBlueInColor',
        },
        {
          value: 'Severely reduced ability to look after yourself or others in your home',
          label: 'questions:emergencySymptoms.symptoms.severelyReducedAbility',
        },
        {
          value: 'none',
          label: 'questions:noneOfThese',
        },
      ],
      boxWidth: '320px',
      bodyStyle: {
        paddingTop: '20px',
        paddingBottom: '33px',
      },
    },
  },
  {
    id: '1',
    path: '/questions/for-whom-answering',
    component: (props) => <CommonTemplate  {...props}/>,
    props: {
      fieldName: 'forWhomAnswering',
      prevRoutePath: '/questions/emergency-symptoms',
      nextRoutePath: '/questions/age',
      question: 'questions:question-1.for-whom-answering',
      isMultiSelect: false,
      options: [
        {
          value: 'For myself',
          label: 'questions:question-1.forMyself',
        },
        {
          value: 'For someone else',
          label: 'questions:question-1.forSomeoneElse',
        },
      ],
      bodyStyle: {
        paddingTop: '113px',
        paddingBottom: '126px',
      },
    },
  },
  {
    id: '2',
    path: '/questions/age',
    component: (props) => <SliderTemplate  {...props}/>,
    props: {
      fieldName: 'age',
      prevRoutePath: '/questions/for-whom-answering',
      nextRoutePath: '/questions/gender',
      question: 'questions:question-2.whatIsYourAge',
      sliderTextValue: 'questions:question-2.yearOld',
      sliderMinValue: 5,
      sliderMaxValue: 95,
      svgIcon: () => <FamilySvg />,
      bodyStyle: {
        paddingTop: '53px',
        paddingBottom: '90px',
      },
      titleStyle: {
        paddingTop: '24px',
      },
    },
  },
  {
    id: '3',
    path: '/questions/gender',
    component: (props) => <RadioBtnTemplate {...props} />,
    props: {
      fieldName: 'gender',
      prevRoutePath: '/questions/age',
      nextRoutePath: '/questions/family',
      question: 'questions:question-3.whatIsYourGender',
      options: [
        {
          value: 'female',
          label: 'questions:question-3.female',
        },
        {
          value: 'male',
          label: 'questions:question-3.male',
        },
      ],
      bodyStyle: {
        paddingTop: '80px',
        paddingBottom: '116px',
      },
      titleStyle: {
        paddingTop: '48px',
      },
    },
  },
  {
    id: '4',
    path: '/questions/family',
    component: (props) => <Family  {...props}/>,
    props: {
      fieldName: 'family',
      prevRoutePath: '/questions/gender',
      nextRoutePath: '/questions/work-in-a-healthcare-facility',
      question: 'questions:question-4.howManyPeopleDoYouLiveWith',
    },
  },
  {
    id: '5',
    path: '/questions/work-in-a-healthcare-facility',
    component: (props) => <RadioBtnTemplate  {...props}/>,
    props: {
      fieldName: 'isWorkInHealthcareFacility',
      prevRoutePath: '/questions/family',
      nextRoutePath: '/questions/symptoms',
      question: 'questions:question-5.workInHealthcareFacility',
      options: singleAnswerOptions.slice(0, 2),
      svgIcon: () => <HealthWorkersSvg />,
      bodyStyle: {
        paddingTop: '64px',
        paddingBottom: '101px',
      },
      titleStyle: {
        paddingTop: '24px',
      },
    },
  },
  {
    id: '6',
    path: '/questions/symptoms',
    component: (props) => <CommonTemplate  {...props}/>,
    props: {
      fieldName: 'symptoms',
      prevRoutePath: '/questions/work-in-a-healthcare-facility',
      nextRoutePath: '/questions/additional-symptoms',
      question: 'questions:question-6.haveSymptoms',
      hint: 'questions:hint',
      isMultiSelect: true,
      options: [
        {
          value: 'Cough',
          label: 'questions:question-6.symptoms.cough',
        },
        {
          value: 'Feeling hot or feverish',
          label: 'questions:question-6.symptoms.feelingHotOrFeverish',
        },
        {
          value: 'Difficulty breathing',
          label: 'questions:question-6.symptoms.difficultyBreathing',
        },
        {
          value: 'none',
          label: 'questions:noneOfThese',
        },
      ],
      bodyStyle: {
        paddingTop: '34px',
        paddingBottom: '48px',
      },
    },
  },
  {
    id: '7',
    path: '/questions/additional-symptoms',
    component: (props) => <CommonTemplate  {...props}/>,
    props: {
      fieldName: 'additionalSymptoms',
      prevRoutePath: '/questions/symptoms',
      nextRoutePath: '/questions/exposed-a-fever-or-a-cough-in-the-past-14-days',
      question: 'questions:question-6.haveSymptoms',
      hint: 'questions:hint',
      isMultiSelect: true,
      options: [
        {
          value: 'Aches and pains in muscles',
          label: 'questions:question-6.symptoms.achesAndPainsInMuscles',
        },
        {
          value: 'Sore throat',
          label: 'questions:question-6.symptoms.soreThroat',
        },
        {
          value: 'Diarrhoea',
          label: 'questions:question-6.symptoms.diarrhoea',
        },
        {
          value: 'Nausea',
          label: 'questions:question-6.symptoms.nausea',
        },
        {
          value: 'Runny nose',
          label: 'questions:question-6.symptoms.runnyNose',
        },
        {
          value: 'I have lost my sense of taste and smell',
          label: 'questions:question-6.symptoms.iHaveLostMySenseOfTasteAndSmell',
        },
        {
          value: 'none',
          label: 'questions:noneOfThese',
        },
      ],
      bodyStyle: {
        paddingTop: '34px',
        paddingBottom: '46px',
      },
    },
  },
  {
    id: '8',
    path: '/questions/exposed-a-fever-or-a-cough-in-the-past-14-days',
    component: (props) => <CommonTemplate  {...props}/>,
    props: {
      fieldName: 'isExposedFeverOrCoughPast14Days',
      prevRoutePath: '/questions/additional-symptoms',
      nextRoutePath: '/questions/had-a-fever-in-the-last-14-days',
      question: 'questions:question-8.exposedAFeverOrACoughInThePast14Days',
      isMultiSelect: false,
      options: [...singleAnswerOptions],
      bodyStyle: {
        paddingTop: '64px',
        paddingBottom: '78px',
      },
    },
  },
  {
    id: '9',
    path: '/questions/had-a-fever-in-the-last-14-days',
    component: (props) => <CommonTemplate  {...props}/>,
    props: {
      fieldName: 'hadAFeverInTheLast14Days',
      prevRoutePath: '/questions/exposed-a-fever-or-a-cough-in-the-past-14-days',
      nextRoutePath:
        '/questions/experienced-a-new-and-continuous-cough-in-the-last-14-days',
      additionalRoute: '/questions/days-had-a-fever',
      question: 'questions:question-9.hadAFeverInTheLast14Days',
      hint: 'questions:question-9.hint',
      isMultiSelect: false,
      options: [...singleAnswerOptions],
      svgIcon: () => <FeverSvg />,
      bodyStyle: {
        paddingTop: '35px',
        paddingBottom: '36px',
      },
      titleStyle: {
        paddingTop: '16px',
      },
    },
  },
  {
    id: '10',
    path: '/questions/experienced-a-new-and-continuous-cough-in-the-last-14-days',
    component: (props) => <CommonTemplate  {...props}/>,
    props: {
      fieldName: 'hadAContinuousCoughInTheLast14Days',
      prevRoutePath: '/questions/had-a-fever-in-the-last-14-days',
      nextRoutePath: '/questions/had-a-loss-in-taste-and-smell-in-the-last-14-days',
      additionalRoute: '/questions/days-had-a-new-and-continuous-cough',
      question: 'questions:question-10.hadAContinuousCoughInTheLast14Days',
      hint: 'questions:question-10.hint',
      isMultiSelect: false,
      options: [...singleAnswerOptions],
      svgIcon: () => <CoughingSvg/>,
      bodyStyle: {
        paddingTop: '35px',
        paddingBottom: '36px',
      },
      titleStyle: {
        paddingTop: '16px',
      },
    },
  },

  {
    id: '11',
    path: '/questions/had-a-loss-in-taste-and-smell-in-the-last-14-days',
    component: (props) => <CommonTemplate  {...props}/>,
    props: {
      fieldName: 'hadALossInTasteAndSmellInTheLast14Days',
      prevRoutePath:
        '/questions/experienced-a-new-and-continuous-cough-in-the-last-14-days',
      nextRoutePath: '/questions/been-diagnosed-with-conditions',
      additionalRoute: '/questions/days-had-a-loss-in-taste-and-smell',
      recommendedRoute: '/patient-data/green-category',
      question: 'questions:question-11.question',
      isMultiSelect: false,
      options: [...singleAnswerOptions],
      svgIcon: () => <FeverSvg/>,
      bodyStyle: {
        paddingTop: '35px',
        paddingBottom: '36px',
      },
      titleStyle: {
        paddingTop: '16px',
      },
    },
  },

  {
    id: '12',
    path: '/questions/been-diagnosed-with-conditions',
    component: (props) => <CommonTemplate  {...props}/>,
    props: {
      fieldName: 'beenDiagnosedWithConditions',
      prevRoutePath: '/questions/had-a-loss-in-taste-and-smell-in-the-last-14-days',
      nextRoutePath: '/questions/have-the-following',
      question: 'questions:question-12.beenDiagnosedWithConditions',
      hint: 'questions:hint',
      isMultiSelect: true,
      options: [
        {
          value: 'High blood pressure',
          label: 'questions:question-12.conditions.highBloodPressure',
        },
        {
          value: 'Heart disease',
          label: 'questions:question-12.conditions.heartDisease',
        },
        {
          value: 'Obesity (BMI over 40)',
          label: 'questions:question-12.conditions.obesityBbiOver40',
        },
        {
          value: 'Diabetes',
          label: 'questions:question-12.conditions.diabetes',
        },
        {
          value: 'none',
          label: 'questions:noneOfThese',
        },
      ],
      bodyStyle: {
        paddingTop: '35px',
        paddingBottom: '48px',
      },
    },
  },
  {
    id: '13',
    path: '/questions/have-the-following',
    component: (props) => <CommonTemplate  {...props}/>,
    props: {
      fieldName: 'haveTheFollowing',
      prevRoutePath: '/questions/been-diagnosed-with-conditions',
      nextRoutePath: '/questions/apply',
      question: 'questions:question-13.haveTheFollowing',
      hint: 'questions:hint',
      isMultiSelect: true,
      options: [
        {
          value: 'Kidney, lung, or liver disease',
          label: 'questions:question-13.conditions.kidneyLungOrLiverDisease',
        },
        {
          value: 'Spleen problems such as sickle cell disease or spleen removal',
          label: 'questions:question-13.conditions.spleenProblems',
        },
        {
          value: "Neurological disorder such as Parkinson's or multiple sclerosis",
          label: 'questions:question-13.conditions.neurologicalDisorder',
        },
        {
          value:
            'Weak or lowered immune system due to HIV or taking long-term oral steroid tablets',
          label: 'questions:question-13.conditions.weak',
        },
        {
          value: 'none',
          label: 'questions:noneOfThese',
        },
      ],
      boxWidth: '320px',
      bodyStyle: {
        paddingTop: '56px',
        paddingBottom: '36px',
      },
      titleStyle: {
        paddingBottom: '55px',
      },
    },
  },
  {
    id: '14',
    path: '/questions/apply',
    component: (props) => <CommonTemplate  {...props}/>,
    props: {
      fieldName: 'isApplyToYou',
      prevRoutePath: '/questions/have-the-following',
      nextRoutePath: '',
      question: 'questions:question-14.isApplyToYou',
      hint: 'questions:hint',
      isLastQuestion: true,
      isMultiSelect: true,
      options: [
        {
          value: 'Pregnant',
          label: 'questions:question-14.conditions.pregnant',
        },
        {
          value: 'Have had an organ transplant',
          label: 'questions:question-14.conditions.haveHadAnOrganTransplant',
        },
        {
          value: 'Had a bone marrow transplant in the past 6 months',
          label:
            'questions:question-14.conditions.hadBoneMarrowTransplantInThePast6Months',
        },
        {
          value: 'Currently having chemotherapy or radiotherapy',
          label:
            'questions:question-14.conditions.currentlyHavingChemotherapyOrRadiotherapy',
        },
        {
          value: 'Being treated for leukaemia, lymphoma or myeloma',
          label:
            'questions:question-14.conditions.beingTreatedForLeukaemiaLymphomaOrMyeloma',
        },
        {
          value:
            'Currently diagnosed with cystic fibrosis, severe asthma or server COPD (Chronic obstructive pulmonary disease)',
          label: 'questions:question-14.conditions.currentlyDiagnosedWithCysticFibrosis',
        },
        {
          value: 'Kidney disease and on dialysis',
          label: 'questions:question-14.conditions.kidneyDiseaseAndOnDialysis',
        },

        {
          value: 'none',
          label: 'questions:noneOfThese',
        },
      ],
      boxWidth: '320px',
      bodyStyle: {
        paddingTop: '56px',
        paddingBottom: '36px',
      },
      titleStyle: {
        paddingBottom: '55px',
      },
    },
  },
];

const mobileFromYesAnswerRoutes = [
  {
    id: '9',
    path: '/questions/days-had-a-fever',
    component: (props) => <SliderTemplate  {...props}/>,
    props: {
      fieldName: 'hadAFeverDays',
      prevRoutePath: '/questions/had-a-fever-in-the-last-14-days',
      nextRoutePath:
        '/questions/experienced-a-new-and-continuous-cough-in-the-last-14-days',
      question: 'questions:questionsFromYesAnswer.howManyDaysHaveYouHadAFever',
      sliderTextValue: 'questions:questionsFromYesAnswer.days',
      sliderMinValue: 1,
      sliderMaxValue: 14,
      svgIcon: () => <DaysSvg/>,
      bodyStyle: {
        paddingTop: '43px',
        paddingBottom: '80px',
      },
      titleStyle: {
        paddingTop: '16px',
      },
    },
  },
  {
    id: '10',
    path: '/questions/days-had-a-new-and-continuous-cough',
    component: (props) => <SliderTemplate  {...props}/>,
    props: {
      fieldName: 'hadAContinuousCoughDays',
      prevRoutePath:
        '/questions/experienced-a-new-and-continuous-cough-in-the-last-14-days',
      nextRoutePath: '/questions/had-a-loss-in-taste-and-smell-in-the-last-14-days',
      question:
        'questions:questionsFromYesAnswer.howManyDaysHaveYouHadAnewAndContinuousCough',
      sliderTextValue: 'questions:questionsFromYesAnswer.days',
      sliderMinValue: 1,
      sliderMaxValue: 14,
      svgIcon: () => <DaysSvg/>,
      bodyStyle: {
        paddingTop: '43px',
        paddingBottom: '80px',
      },
      titleStyle: {
        paddingTop: '16px',
      },
    },
  },
  {
    id: '11',
    path: '/questions/days-had-a-loss-in-taste-and-smell',
    component: (props) => <SliderTemplate  {...props}/>,
    props: {
      fieldName: 'hadALossInTasteAndSmellDays',
      prevRoutePath: '/questions/had-a-loss-in-taste-and-smell-in-the-last-14-days',
      nextRoutePath: '/questions/been-diagnosed-with-conditions',
      question:
        'questions:questionsFromYesAnswer.howManyDaysHaveYouHadALossOfTasteAndSmell',
      sliderTextValue: 'questions:questionsFromYesAnswer.days',
      sliderMinValue: 1,
      sliderMaxValue: 14,
      svgIcon: () => <DaysSvg/>,
      bodyStyle: {
        paddingTop: '43px',
        paddingBottom: '80px',
      },
      titleStyle: {
        paddingTop: '16px',
      },
    },
  },
];

export const getMobileQuestionRoutes = () => mobileQuestionRoutes;

export const getMobileQuestionFromYesAnswerRoutes = () => mobileFromYesAnswerRoutes;
