import React, { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Box from 'ui-box';
import { Typography, Button } from 'antd';
import { ReactComponent as WarningSvg } from '../../../assets/icons/warning.svg';
import Logo from '../../../components/common/Logo';

const { Title, Paragraph, Text } = Typography;

const styles = {
  paragraph: {
    color: '#404047',
  },
};

const renderAlertItem = (text) => (
  <Box textAlign="justify" fontSize={14} lineHeight="17px" marginBottom={18}>
    <Text>{text}</Text>
  </Box>
);

const disclaimerPages = {
  '1': ({ t, onSubmit }) => (
    <>
      <Box textAlign="justify" lineHeight="19px" paddingTop={20} paddingBottom={18}>
        <Paragraph style={styles.paragraph}>
          <Trans i18nKey="welcome:disclaimer.generalInfo">
            The <strong>AlemHealth COVID Triage</strong>
          </Trans>
        </Paragraph>
      </Box>
      <Box padding={10} backgroundColor="#dcf2fb" borderRadius={4} marginBottom={70}>
        <Box textAlign="center" fontWeight={500}>
          <Paragraph style={styles.paragraph}>
            {t('welcome:disclaimer.alert.title')}
          </Paragraph>
        </Box>
        <ul>
          <li>{renderAlertItem(t('welcome:disclaimer.alert.inAnEmergency'))}</li>
          <li>{renderAlertItem(t('welcome:disclaimer.alert.latestMedicalGuidance'))}</li>
          <li>{renderAlertItem(t('welcome:disclaimer.alert.health'))}</li>
        </ul>
      </Box>
      <Button block size="large" type="primary" onClick={onSubmit}>
        {t('common:button.oKiUnderstand')}
      </Button>
    </>
  ),
  '2': ({ t, onSubmit }) => (
    <>
      <Box
        padding={10}
        backgroundColor="#dcf2fb"
        borderRadius={4}
        marginBottom={100}
        marginTop={37}
      >
        <Box textAlign="center" fontWeight={500}>
          <Paragraph style={styles.paragraph}>
            {t('welcome:disclaimer.alert2.title')}
          </Paragraph>
        </Box>
        <ul>
          <li>{renderAlertItem(t('welcome:disclaimer.alert2.section1'))}</li>
          <li>{renderAlertItem(t('welcome:disclaimer.alert2.section2'))}</li>
          <li>{renderAlertItem(t('welcome:disclaimer.alert2.section3'))}</li>
          <li>{renderAlertItem(t('welcome:disclaimer.alert2.section4'))}</li>
          <li>{renderAlertItem(t('welcome:disclaimer.alert2.section5'))}</li>
          <li>{renderAlertItem(t('welcome:disclaimer.alert2.section6'))}</li>
          <li>{renderAlertItem(t('welcome:disclaimer.alert2.section7'))}</li>
        </ul>
      </Box>
      <Button block size="large" type="primary" onClick={onSubmit}>
        {t('welcome:disclaimer.alert2.btn')}
      </Button>
    </>
  ),
};

function MobileDisclaimer() {
  const [page, setPage] = useState(1);

  const { t } = useTranslation(['common', 'welcome']);
  const history = useHistory();

  const onSubmit = () => {
    if (page === 2) {
      history.push('/welcome/introduction');
    } else {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const DisclaimerPage = disclaimerPages[page];

  return (
    <>
      <Logo rootStyle={{ padding: 20 }} />
      <Box paddingX={20} paddingBottom={36}>
        <Box textAlign="center" marginTop={18} marginBottom={24}>
          <WarningSvg />
        </Box>
        <Typography>
          <Title level={2} className="text-center">
            {t('welcome:disclaimer.importantInfo')}
          </Title>
          <DisclaimerPage t={t} onSubmit={onSubmit} />
        </Typography>
      </Box>
    </>
  );
}

export default MobileDisclaimer;
