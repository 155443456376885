import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { Radio } from 'antd';

const ControlledRadioButtonField = ({ name, options, radioBtnStyles }) => {
  // eslint-disable-next-line
  const [field, meta, helpers] = useField(name);

  return (
    <Radio.Group {...field} size="large" style={radioBtnStyles.radioGroup}>
      {options.map(({ value, label }) => (
        <Radio.Button key={value} value={value} style={radioBtnStyles.radioBtn}>
          {label}
        </Radio.Button>
      ))}
    </Radio.Group>
  );
};

ControlledRadioButtonField.defaultProps = {
  options: [],
};

ControlledRadioButtonField.defaultProps = {
  options: [],
  radioBtnStyles: {
    radioGroup: {
      width: '100%',
      textAlign: 'center',
    },
    radioBtn: {
      width: '50%',
      height: '50px',
      lineHeight: '48px',
    },
  },
};

const { arrayOf, oneOfType, string, bool, shape } = PropTypes;

ControlledRadioButtonField.propTypes = {
  name: string.isRequired,
  options: arrayOf(
    shape({
      value: oneOfType([string, bool]).isRequired,
      label: string.isRequired,
    })
  ),
  radioBtnStyles: shape({
    radioGroup: shape({}),
    radioBtn: shape({}),
  }),
};

export default ControlledRadioButtonField;
