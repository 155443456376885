import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'ui-box';
import { Row, Col, Typography } from 'antd';
import { ReactComponent as AmberDoctor } from '../../../assets/icons/red-doctor.svg';
import RecommendedCategoryAlert from '../../../components/mobile/RecommendedCategoryAlert';
import RecommendedSocialShare from '../../../components/mobile/RecommendedSocialShare';
import Footer from './Footer';

const { Title } = Typography;

const styles = {
  col: {
    display: 'flex',
  },
  paragraph: {
    color: '#404047',
  },
};

function RedCategory() {
  const { t } = useTranslation('recommended');

  return (
    <>
      <Row justify="center">
        <Col style={styles.col}>
          <AmberDoctor />
        </Col>
      </Row>
      <Box paddingTop={28} textAlign="center">
        {/* <Title level={4}>{t('thankYou')}</Title> */}
        <Box>
          <Title level={4}>{t('redCategory.symptoms')}</Title>
        </Box>
      </Box>
      <Box paddingTop={19}>
        <RecommendedCategoryAlert
          type="error"
          message={`${t('redCategory.shouldDo')} ${t('medicalTeam')}`}
          showGuideLinks={false}
        />
      </Box>
      <Box paddingY={28}>
        <RecommendedSocialShare />
      </Box>
      <Box paddingBottom={25}>
        <RecommendedCategoryAlert
          type="error"
          message={t('redCategory.followTheGuidance')}
        />
      </Box>
      <Footer />
    </>
  );
}

export default RedCategory;
