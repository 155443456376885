import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from 'ui-box';
import { Col, Row, Typography } from 'antd';
import ControlledOptionsFieldArray from '../../../components/mobile/form-helpers/ControlledOptionsFieldArray';

const { Title, Paragraph } = Typography;

const styles = {
  col: {
    display: 'flex',
  },
  hint: {
    color: 'rgba(0, 0, 0, 0.4)',
  },
};

function MobileCommonTemplate({
  question,
  hint,
  svgIcon: SvgIcon,
  bodyStyle,
  titleStyle,
  isMultiSelect,
  ...restControlledOptionsFieldArrayProps
}) {
  const { t } = useTranslation();

  return (
    <Box {...bodyStyle}>
      {SvgIcon && (
        <Row justify="center">
          <Col style={styles.col}>
            <SvgIcon />
          </Col>
        </Row>
      )}
      <Box paddingBottom={8} {...titleStyle}>
        <Title level={4} className="text-center">
          {t(`${question}`)}
        </Title>
        {hint && (
          <Box color="red">
            <Paragraph className="text-center" style={styles.hint}>
              {t(`${hint}`)}
            </Paragraph>
          </Box>
        )}
      </Box>
      <ControlledOptionsFieldArray isMultiSelect={isMultiSelect} {...restControlledOptionsFieldArrayProps} />
    </Box>
  );
}

MobileCommonTemplate.defaultProps = {
  question: 'Some question',
  hint: '',
  bodyStyle: {},
  titleStyle: {},
};

const { string, shape, func } = PropTypes;

MobileCommonTemplate.propTypes = {
  question: string,
  hint: string,
  svgIcon: func,
  bodyStyle: shape({}),
  titleStyle: shape({}),
};

export default MobileCommonTemplate;
