/* eslint-disable no-shadow */
import { useContext } from 'react';
import AppDispatchContext from '../../context/app-dispatch-context';
import { setScreeningData } from '../../state/app/actions';
import AppStateContext from '../../context/app-state-context';

function useUUID() {
  const state = useContext(AppStateContext);
  const dispatch = useContext(AppDispatchContext);

  const {
    app: { screeningData },
  } = state;

  return {
    screeningData,
    setScreeningData: (screeningData) => dispatch(setScreeningData(screeningData)),
  };
}

export default useUUID;
