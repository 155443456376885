import React from 'react';
import PropTypes from 'prop-types';
import Box from 'ui-box';
import { Col, Row } from 'antd';

function SelectableItemBox({
  selectable,
  isSelected,
  isMultiSelect,
  isTextCentred,
  onClick,
  children,
}) {
  return (
    <Row
      gutter={[16, 0]}
      align="middle"
      justify="center"
      className={`box ${isSelected ? 'selected' : ''} ${
        isTextCentred ? 'text-center' : ''
      } ${selectable ? 'selectable' : ''}`}
    >
      <Col xs={24} onClick={!isSelected || isMultiSelect ? onClick : () => {}}>
        <Box paddingY={8}>{children}</Box>
      </Col>
    </Row>
  );
}

SelectableItemBox.defaultProps = {
  selectable: true,
  isSelected: false,
  isMultiSelect: false,
  isTextCentred: false,
};

const { bool, func, oneOfType, string, element } = PropTypes;

SelectableItemBox.propTypes = {
  selectable: bool,
  isSelected: bool,
  isMultiSelect: bool,
  isTextCentred: bool,
  onClick: func,
  children: oneOfType([string, element]),
};

export default SelectableItemBox;
