import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import { ReactComponent as AlemHealthLogoSvg } from '../../assets/icons/alem_health_logo.svg';

function Logo({ rootStyle, width, height }) {
  return (
    <Row justify="center" style={rootStyle}>
      <Col style={{ display: 'flex' }}>
        <AlemHealthLogoSvg width={width} height={height} />
      </Col>
    </Row>
  );
}

Logo.defaultProps = {
  rootStyle: {},
  width: 244,
  height: 50,
};

const { number, shape } = PropTypes;

Logo.propTypes = {
  rootStyle: shape({}),
  width: number,
  height: number,
};

export default Logo;
