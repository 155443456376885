import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { Button, Col, Row } from 'antd';
import { NONE } from './form-constants';

const styles = {
  btn: {
    width: '125px',
  },
};

const shouldNavigateOnTheAdditionalPage = (path, userAnswer) =>
  path && userAnswer === true;

const shouldNavigateOnTheRecommendedGreenPage = (path, formValues) => {
  const fieldNamesToCheck = [
    'symptoms',
    'additionalSymptoms',
    'hadAFeverInTheLast14Days',
    'hadAContinuousCoughInTheLast14Days',
    'hadALossInTasteAndSmellInTheLast14Days',
  ];

  return (
    path &&
    Object.entries(formValues).every(([key, value]) => {
      if (fieldNamesToCheck.includes(key)) {
        if (Array.isArray(value)) {
          return value[0] === NONE;
        }
        return value === false;
      }
      return true;
    })
  );
};

function MobileQuestionsNavBtn({
  fieldName,
  isLastQuestion,
  prevRoutePath,
  nextRoutePath,
  additionalRoute,
  recommendedRoute,
}) {
  const { t } = useTranslation();
  const history = useHistory();

  const { values, submitForm } = useFormikContext();
  // console.log('values', values);

  const value = values[fieldName];

  const onPrev = () => {
    history.push(prevRoutePath);
  };

  const onNext = () => {
    if (isLastQuestion) {
      submitForm();
    } else if (shouldNavigateOnTheAdditionalPage(additionalRoute, value)) {
      history.push(additionalRoute);
    } else if (shouldNavigateOnTheRecommendedGreenPage(recommendedRoute, values)) {
      submitForm();
    } else {
      history.push(nextRoutePath);
    }
  };

  let isDisabled = true;

  if (Array.isArray(value)) {
    isDisabled = value.length;
  } else if (typeof value === 'string') {
    isDisabled = value;
  } else if (typeof value === 'number') {
    isDisabled = value >= 0;
  }

  return (
    <Row gutter={16} justify="space-between">
      <Col>
        <Button style={styles.btn} size="large" onClick={onPrev}>
          {t('common:button.previous')}
        </Button>
      </Col>
      <Col>
        <Button
          style={styles.btn}
          size="large"
          type="primary"
          disabled={!isDisabled}
          onClick={onNext}
        >
          {t('common:button.next')}
        </Button>
      </Col>
    </Row>
  );
}

MobileQuestionsNavBtn.defaultProps = {
  isLastQuestion: false,
};

const { string, bool } = PropTypes;

MobileQuestionsNavBtn.propTypes = {
  fieldName: string.isRequired,
  isLastQuestion: bool,
  prevRoutePath: string.isRequired,
  nextRoutePath: string.isRequired,
  additionalRoute: string,
  recommendedRoute: string,
};

export default MobileQuestionsNavBtn;
