/* eslint-disable no-console */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import Box from 'ui-box';
import { Button } from 'antd';
import {
  useHistory
} from 'react-router-dom';
import InputField from './form-helpers/InputField';
import PhoneInputField from './form-helpers/PhoneInputField';
import LoadingSpinner from '../common/LoadingSpinner';
import { submitUser } from '../../api';
import { errorNotification, successNotification } from '../../utils/notification-helpers';
import useUUID from '../../containers/hooks/useUUID';
import useScreeningData from '../../containers/hooks/useScreeningData';
import { postNotificationToSlackAPICall } from '../../api/slack';
import useAppData from '../../containers/hooks/useAppData';
import useChangeLanguage from '../../containers/hooks/useChangeLanguage';
import { initialState } from '../../state/app/reducers';


const validateScreeningData = (values) => {

  const {patientId, careLevel, forWhomAnswering, gender, family, isWorkInHealthcareFacility, isExposedFeverOrCoughPast14Days, hadAFeverInTheLast14Days, hadAContinuousCoughInTheLast14Days, hadALossInTasteAndSmellInTheLast14Days } = values

  if(forWhomAnswering === undefined || forWhomAnswering === ''){
    return false
  }

  if(gender === undefined || gender ===''){
    return false
  }

  if(family === undefined || family ===''){
    return false
  }

  if(isWorkInHealthcareFacility === undefined || isWorkInHealthcareFacility  ===''){
    return false
  }

  if(isExposedFeverOrCoughPast14Days === undefined || isExposedFeverOrCoughPast14Days === ''){
    return false
  }

  if(hadAFeverInTheLast14Days === undefined || hadAFeverInTheLast14Days ===''){
    return false
  }

  if(hadAContinuousCoughInTheLast14Days === undefined || hadAContinuousCoughInTheLast14Days ===''){
    return false
  }

  if(hadALossInTasteAndSmellInTheLast14Days === undefined || hadALossInTasteAndSmellInTheLast14Days ===''){
    return false
  }

  if(patientId === undefined || patientId === ''){
    return false
  }

  if(careLevel === undefined || careLevel === ''){
    return false
  }

  return true;
}

function SingUpForm({ onSubmit, onSubmitBtnText, showEmailInput }) {
  const { t } = useTranslation(['common']);
  const { screeningData } = useScreeningData()
  const { setAppData } = useAppData()
  const { lang, direction } = useChangeLanguage();

  const history = useHistory();

  const SignUpSchema = useMemo(
    () =>
      Yup.object().shape({
        firstName: Yup.string().required(t('form.error.required')),
        // lastName: Yup.string().required(t('form.error.required')),
        // fathersName: Yup.string().required(t('form.error.required')),
        phone: Yup.string().required(t('form.error.required')).min(11, t('form.error.required')),
        // lastName: Yup.string().required('Required'),
        email: Yup.string().email(t('form.error.email')),
        // // .required('Required')
      }),
    [t]
  );

  const { uuid } = useUUID();

  const initialValues = {
    firstName: '',
    lastName: '',
    fathersName: '',
    phone: '',
    email: '',
    ...screeningData
  };



  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={SignUpSchema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          const userData = { ...values };
          userData.patientId = uuid;
          if(values.email===''){
            userData.email = `${uuid}@alemhealth.com`
          }
          userData.phone = userData.phone.replace(/\s/g, '').substring(1);

          const isValid = validateScreeningData(userData)
          if(!isValid){
            errorNotification(t('form.error.submit'));
            setSubmitting(false);
            const initialData = {...initialState, lang, direction}
            setAppData(initialData)
            resetForm({ values: initialValues });
            history.push(`/`)
          } else {
            try {
              postNotificationToSlackAPICall({ title: '[New Patient Data]: ', jsonContent: userData })
              await submitUser(userData);
              successNotification(t('form.success.submit'), 2);
              onSubmit(userData);
            } catch (err) {
              console.error(err)
              errorNotification(t('form.error.submit'));
              history.push(`/`)
            } finally {
              setSubmitting(false);
              const initialData = {...initialState, lang, direction}
              setAppData(initialData)
              resetForm({ values: initialValues });
            }
          }
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <InputField
              type="text"
              name="firstName"
              label={t('form.label.firstName')}
              placeholder={t('form.placeholder.patientsName')}
            />
            <InputField
              type="text"
              name="lastName"
              label={t('form.label.lastName')}
              placeholder={t('form.placeholder.lastName')}
            />
            <InputField
              type="text"
              name="fathersName"
              label={t('form.label.fathersName')}
              placeholder={t('form.placeholder.fathersName')}
            />
            <PhoneInputField
              name="phone"
              label={t('form.label.phoneNumber')}
              placeholder={t('form.placeholder.phoneNumber')}
            />
            {showEmailInput && (
              <InputField
                type="email"
                name="email"
                label={t('form.label.emailAddress')}
                placeholder={t('form.placeholder.emailAddress')}
              />
            )}
            <Box paddingTop={36}>
              <Button disabled={isSubmitting} htmlType="submit" type="primary" block>
                {onSubmitBtnText}
              </Button>
            </Box>
            {isSubmitting ? <LoadingSpinner position="full" transparent={false} /> : null}
          </Form>
        )}
      </Formik>
    </div>
  );
}

SingUpForm.defaultProps = {
  onSubmitBtnText: 'Sign Up',
  showEmailInput: true,
};

const { func, string, bool } = PropTypes;

SingUpForm.propTypes = {
  onSubmitBtnText: string,
  showEmailInput: bool,
  onSubmit: func.isRequired,
};

export default SingUpForm;
