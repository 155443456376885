import React from 'react';
import PropTypes, { bool } from 'prop-types';
import { Spin } from 'antd';

const backgroundStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  height: '100%',
  width: '100%',
  backgroundColor: 'rgba(255,255,255,0.6)',
  zIndex: '1011',
};

const fullViewStyle = {
  position: 'absolute',
  transform: 'translate(-50%, -50%)',
  top: '50%',
  left: '50%',
  zIndex: '1011',
};

const blockViewStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

function LoadingSpinner({ position, size, transparent }) {
  let style;

  if (position === 'full') {
    style = fullViewStyle;
  } else if (position === 'block') {
    style = blockViewStyle;
  }

  if (!transparent) {
    return (
      <div style={backgroundStyle}>
        <div style={style}>
          <Spin size={size} />
        </div>
      </div>
    );
  }

  return (
    <div style={style}>
      <Spin size={size} />
    </div>
  );
}

LoadingSpinner.defaultProps = {
  position: 'block',
  size: 'default',
  transparent: true,
};

const { oneOf } = PropTypes;

LoadingSpinner.propTypes = {
  position: oneOf(['full', 'block']),
  size: oneOf(['default', 'small', 'large']),
  transparent: bool,
};

export default LoadingSpinner;
