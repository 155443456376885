import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { Form, Input } from 'antd';

function InputField(props) {
  const { name, label, formItemLayout, ...restInputProps } = props;
  // eslint-disable-next-line
  const [field, meta, helpers] = useField(name);

  return (
    <Form.Item
      {...formItemLayout}
      label={label}
      // hasFeedback
      // validateStatus=""
      help={meta.touched && meta.error}
    >
      <Input {...field} {...restInputProps} size="large" />
    </Form.Item>
  );
}

InputField.defaultProps = {
  formItemLayout: {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  },
};

const { shape, string } = PropTypes;

InputField.propTypes = {
  name: string.isRequired,
  label: string,
  formItemLayout: shape({}),
};

export default InputField;
