import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import Box from 'ui-box';
import { Typography, Row, Col } from 'antd';
import { ReactComponent as FemaleSvg } from '../../../assets/icons/female.svg';
import { ReactComponent as MaleSvg } from '../../../assets/icons/male.svg';
import ControlledRadioButtonField from '../../../components/mobile/form-helpers/ControlledRadioButtonField';
import { FEMALE, MALE } from '../../../components/mobile/form-helpers/form-constants';

const { Title } = Typography;

const styles = {
  col: {
    display: 'flex',
  },
};

const renderGenderSvg = (gender) => (
  <Row justify="center" gutter={[36]}>
    {gender === FEMALE && (
      <Col style={styles.col}>
        <FemaleSvg width={150} height={150} />
      </Col>
    )}
    {gender === MALE && (
      <Col style={styles.col}>
        <MaleSvg width={150} height={150} />
      </Col>
    )}
    {!gender && (
      <>
        <Col style={styles.col}>
          <FemaleSvg width={116} height={116} />
        </Col>
        <Col style={styles.col}>
          <MaleSvg width={116} height={116} />
        </Col>
      </>
    )}
  </Row>
);

const renderSvg = (SvgIcon, type) => {
  if (SvgIcon) {
    return (
      <Row justify="center">
        <Col style={styles.col}>
          <SvgIcon />
        </Col>
      </Row>
    );
  }

  return renderGenderSvg(type);
};

function RadioBtnTemplate({
  question,
  fieldName,
  options,
  svgIcon,
  bodyStyle,
  titleStyle,
}) {
  const { t } = useTranslation();
  const { values } = useFormikContext();

  const opt = useMemo(
    () =>
      options.map((option) => ({
        ...option,
        label: t(option.label),
      })),
    // eslint-disable-next-line
    []
  );

  return (
    <Box {...bodyStyle}>
      {renderSvg(svgIcon, values.gender)}
      <Box paddingBottom={16} {...titleStyle}>
        <Title level={4} className="text-center">
          {t(`${question}`)}
        </Title>
      </Box>
      <div>
        <ControlledRadioButtonField name={fieldName} options={opt} />
      </div>
    </Box>
  );
}

const { string, arrayOf, oneOfType, bool, shape, func } = PropTypes;

RadioBtnTemplate.defaultProps = {
  question: 'Some question',
  options: [],
  bodyStyle: {},
  titleStyle: {},
};

RadioBtnTemplate.propTypes = {
  question: string,
  fieldName: string.isRequired,
  options: arrayOf(
    shape({
      value: oneOfType([string, bool]).isRequired,
      label: string.isRequired,
    })
  ),
  svgIcon: func,
  bodyStyle: shape({}),
  titleStyle: shape({}),
};

export default RadioBtnTemplate;
