import { useContext } from 'react';
import AppDispatchContext from '../../context/app-dispatch-context';
import { setAppData } from '../../state/app/actions';
import AppStateContext from '../../context/app-state-context';

function useAppData() {
  const state = useContext(AppStateContext);
  const dispatch = useContext(AppDispatchContext);

  const {
    app : appData } = state;

  return {
    appData,
    setAppData: (newAppData) => dispatch(setAppData(newAppData)),
  };
}

export default useAppData;
