import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import Box from 'ui-box';
import { Typography, Divider } from 'antd';
import SelectableItemBox from '../../../components/mobile/SelectableItemBox';
import ControlledSliderField from '../../../components/mobile/form-helpers/ControlledSliderField';
import { ReactComponent as MobileFamilySvg } from '../../../assets/icons/family.svg';
import { NONE } from '../../../components/mobile/form-helpers/form-constants';

const { Title } = Typography;

function MobileFamily({ question, fieldName }) {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext();

  const getCount = () => {
    if (values[fieldName] === NONE) {
      return 1;
    }
    return values[fieldName] || 1;
  };

  return (
    <>
      <Box paddingTop={53} display="flex" justifyContent="center">
        <MobileFamilySvg />
      </Box>
      <Box paddingTop={24} paddingBottom={16}>
        <Title level={4} className="text-center">
          {t(`${question}`)}
        </Title>
      </Box>
      <Box paddingBottom={8} width={150} marginY={0} marginX="auto">
        <SelectableItemBox
          isTextCentred
          isSelected={values[fieldName] === NONE}
          onClick={() => setFieldValue(fieldName, NONE)}
        >
          {t('questions:question-4.iLiveAlone')}
        </SelectableItemBox>
      </Box>
      <Divider style={{ color: 'rgba(0, 0, 0, 0.5)' }}>{t('common:or')}</Divider>
      <Box paddingTop={8} paddingBottom={40} width={150} marginY={0} marginX="auto">
        <SelectableItemBox
          isTextCentred
          isSelected={values[fieldName] >= 1}
          onClick={() => setFieldValue(fieldName, 1)}
        >
          {t('questions:question-4.with', { count: getCount() })}
        </SelectableItemBox>
      </Box>
      <Box paddingBottom={60} maxWidth="80%" marginY={0} marginX="auto">
        <ControlledSliderField name={fieldName} min={1} max={20} />
      </Box>
    </>
  );
}

const { string } = PropTypes;

MobileFamily.defaultProps = {
  question: 'questions:question-4.howManyPeopleDoYouLiveWith',
};

MobileFamily.propTypes = {
  question: string,
  fieldName: string.isRequired,
};

export default MobileFamily;
