import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { Slider } from 'antd';

const ControlledSliderField = ({ name, min, max, marks }) => {
  // eslint-disable-next-line
  const [field, meta, helpers] = useField(name);

  const sliderMarksProps = {
    [min]: min,
    [max]: {
      style: {},
      label: <strong>{max}+</strong>,
    },
    ...marks,
  };

  return (
    <Slider
      min={min}
      max={max}
      marks={sliderMarksProps}
      value={meta.value}
      onChange={(val) => helpers.setValue(val)}
    />
  );
};

ControlledSliderField.defaultProps = {
  min: 5,
  max: 95,
  marks: {},
};

const { string, number, shape } = PropTypes;

ControlledSliderField.propTypes = {
  name: string.isRequired,
  min: number,
  max: number,
  marks: shape({}),
};

export default ControlledSliderField;
