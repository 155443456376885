import React from 'react';
// import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Box from 'ui-box';
import { Row, Col, Typography } from 'antd';
// import SingUpForm from '../../../components/mobile/SignUpForm';
import { ReactComponent as AmberDoctor } from '../../../assets/icons/amber-doctor.svg';
import RecommendedCategoryAlert from '../../../components/mobile/RecommendedCategoryAlert';
import RecommendedSocialShare from '../../../components/mobile/RecommendedSocialShare';
import Footer from './Footer';

const { Title, Paragraph } = Typography;

const styles = {
  col: {
    display: 'flex',
  },
};

function AmberCategory() {
  const { t } = useTranslation('recommended');

  return (
    <>
      <Row justify="center">
        <Col style={styles.col}>
          <AmberDoctor />
        </Col>
      </Row>
      <Box paddingTop={28} textAlign="center">
        {/* <Title level={4}>{t('thankYou')}</Title> */}
        <Box>
          <Title level={4}>{t('amberCategory.symptoms')}</Title>
        </Box>
      </Box>
      <Box paddingTop={19}>
        <RecommendedCategoryAlert
          type="warning"
          message={`${t('amberCategory.shouldDo')} ${t('medicalTeam')}`}
          showGuideLinks={false}
        />
      </Box>
      <Box paddingY={28}>
        <RecommendedSocialShare />
      </Box>
      <Box paddingBottom={12} textAlign="justify">
        <Paragraph className="primary-heading-color">
          {t('amberCategory.IfYourSymptomsWorsenDramatically')}
        </Paragraph>
      </Box>
      <RecommendedCategoryAlert
        type="warning"
        message={t('amberCategory.followTheGuidance')}
      />
      <Box paddingTop={38}>
        <Footer />
      </Box>
    </>
  );
}

export default AmberCategory;
