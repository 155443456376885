import { SET_LANGUAGE, SET_LOCATION, SET_ANSWERS, SET_UUID, SET_SCREENING_DATA, SET_APP_DATA } from './types';

export const setLanguage = (lang, direction) => ({
  type: SET_LANGUAGE,
  lang,
  direction,
});

export const setLocation = (location) => ({
  type: SET_LOCATION,
  location,
});

export const setAnswers = (answers) => ({
  type: SET_ANSWERS,
  answers,
});

export const setUUID = (uuid) => ({
  type: SET_UUID,
  uuid,
});

export const setScreeningData = (screeningData) => ({
  type: SET_SCREENING_DATA,
  screeningData,
});

export const setAppData = (appData) => ({
  type: SET_APP_DATA,
  appData
});