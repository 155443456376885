import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import Box from 'ui-box';
import { Col, Row, Typography } from 'antd';
import SelectableItemBox from '../../../components/mobile/SelectableItemBox';
import ControlledSliderField from '../../../components/mobile/form-helpers/ControlledSliderField';

const { Title } = Typography;

const styles = {
  col: {
    display: 'flex',
  },
};

function MobileSliderTemplate({
  question,
  fieldName,
  sliderTextValue,
  sliderMinValue,
  sliderMaxValue,
  svgIcon: SvgIcon,
  bodyStyle,
  titleStyle,
}) {
  const { t } = useTranslation();
  const { values } = useFormikContext();

  return (
    <Box {...bodyStyle}>
      {SvgIcon && (
        <Row justify="center">
          <Col style={styles.col}>
            <SvgIcon />
          </Col>
        </Row>
      )}
      <Box paddingBottom={16} {...titleStyle}>
        <Title level={4} className="text-center">
          {t(`${question}`)}
        </Title>
      </Box>
      <Box paddingBottom={32} width={150} marginY={0} marginX="auto">
        <SelectableItemBox isTextCentred selectable={false}>
          {t(`${sliderTextValue}`, { count: values[fieldName] })}
        </SelectableItemBox>
      </Box>
      <Box maxWidth="80%" marginY={0} marginX="auto">
        <ControlledSliderField
          name={fieldName}
          min={sliderMinValue}
          max={sliderMaxValue}
        />
      </Box>
    </Box>
  );
}

MobileSliderTemplate.defaultProps = {
  question: 'questions:question-2.whatIsYourAge',
  bodyStyle: {},
  titleStyle: {},
};

const { string, number, shape, func } = PropTypes;

MobileSliderTemplate.propTypes = {
  question: string,
  fieldName: string.isRequired,
  sliderTextValue: string,
  sliderMinValue: number,
  sliderMaxValue: number,
  svgIcon: func,
  bodyStyle: shape({}),
  titleStyle: shape({}),
};

export default MobileSliderTemplate;
