import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ErrorBoundary from 'antd/es/alert/ErrorBoundary';

function AntdAlertErrorBoundary({ message, description, children }) {
  const { t } = useTranslation(['common']);

  const desc = description || t('common:error.unspecific');

  return (
    <ErrorBoundary message={message} description={desc}>
      {children}
    </ErrorBoundary>
  );
}

const { string, element } = PropTypes;

AntdAlertErrorBoundary.propTypes = {
  children: element,
  message: string,
  description: string,
};

export default AntdAlertErrorBoundary;
