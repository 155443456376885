import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Box from 'ui-box';
import { Row, Col, Menu, Dropdown } from 'antd';
import { ShareAltOutlined } from '@ant-design/icons';
import Logo from '../common/Logo';
import { ReactComponent as LanguageSvg } from '../../assets/icons/language.svg';
import useChangeLanguage from '../../containers/hooks/useChangeLanguage';

const styles = {
  row: {
    height: '100%',
  },
  col: {
    display: 'flex',
  },
  menuItem: {
    fontSize: 16,
    fontWeight: 500,
  },
  icon: {
    fontSize: '24px',
    cursor: 'pointer',
  },
};


function MobileHeader() {
  const { t } = useTranslation(['common', 'welcome']);

  const location = useLocation();

  const isRecommendedRoute = location.pathname.includes('recommended-medical-care');

  const { langOptions, lang, direction, changeLanguage } = useChangeLanguage();

  const onMenuClick = ({ key }) => {
    const [langSuffix, dir] = key.split('-');
    changeLanguage(langSuffix, dir);
  };

  const menu = (
    <Menu onClick={onMenuClick} selectedKeys={[`${lang}-${direction}`]}>
      <Menu.Item>{t('welcome:myPreferredLanguage')}</Menu.Item>
      {langOptions.map(({ value, direction: dir }) => (
        <Menu.Item key={`${value}-${dir}`} style={styles.menuItem}>
          {t(`common:language.${value}`)}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Box
      height={60}
      paddingX={20}
      backgroundColor="#fff"
      boxShadow="0px 0px 4px rgba(0, 0, 0, 0.15)"
    >
      <Row align="middle" justify="space-between" style={styles.row}>
        <Col style={styles.col}>
          {isRecommendedRoute ? (
            <a href="#share-button"><ShareAltOutlined style={styles.icon} className="primary-color" /></a>
          ) : (
            // <MenuOutlined style={styles.icon} className="primary-heading-color" />
            null
          )}
        </Col>
        <Col>
          <a href={`https://${t('common:alemHealthCovidUrl')}`}>
            <Logo width={123} height={25} />
          </a>
        </Col>
        <Col style={styles.col}>
          <Dropdown overlay={menu} trigger={['click']}>
            <LanguageSvg />
          </Dropdown>
        </Col>
      </Row>
    </Box>
  );
}

export default MobileHeader;
