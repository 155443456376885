import { message } from 'antd';

const NOTIFICATION_MESSAGE_DURATION = 5;

export const basicNotification = (
  textToShow,
  duration = NOTIFICATION_MESSAGE_DURATION
) => {
  return message.info(textToShow, duration);
};

export const errorNotification = (
  textToShow,
  duration = NOTIFICATION_MESSAGE_DURATION
) => {
  return message.error(textToShow, duration);
};

export const successNotification = (
  textToShow,
  duration = NOTIFICATION_MESSAGE_DURATION
) => {
  return message.success(textToShow, duration);
};

export const warningNotification = (
  textToShow,
  duration = NOTIFICATION_MESSAGE_DURATION
) => {
  return message.warning(textToShow, duration);
};

export const dataLoadingNotification = (textToShow) => {
  return message.loading(textToShow, 0);
};

export const clearNotification = () => {
  message.destroy();
};
