/* eslint-disable no-alert */
/* eslint-disable no-console */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Box from 'ui-box';
import { Row, Col, Typography, Button } from 'antd';
// import { set } from "ui-box/dist/cache";
import { geolocated, geoPropTypes } from 'react-geolocated';
import { v4 as uuidv4 } from 'uuid';
import { ReactComponent as LocationSvg } from '../../../assets/icons/location.svg';
import Logo from '../../../components/common/Logo';
import { getLocationData } from '../../../api';
import useLocation from '../../../containers/hooks/useLocation';
import useUUID from '../../../containers/hooks/useUUID';

const { Title } = Typography;

const styles = {
  root: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  btn: {
    width: '200px',
  },
};

const MobileLocationAccess = ({
  coords,
  isGeolocationAvailable,
  isGeolocationEnabled,
}) => {
  const { t } = useTranslation(['common', 'welcome']);
  const history = useHistory();

  const { setLocation } = useLocation();
  const { setUUID } = useUUID();

  const onNext = () => {
    const patientId = uuidv4();
    setUUID(patientId.toString());
    history.push('/welcome/disclaimer');
  };

  const onYes = async () => {
    const { latitude, longitude } = coords;
    try {
      const { provinceName, countryName, countryCode } = await getLocationData(
        latitude,
        longitude
      );
      setLocation({ latitude, longitude, provinceName, countryName, countryCode });
      onNext();
    } catch (err) {
      console.error(err)
      alert(t('common:form.error.submit'));
    }
  };

  const renderGeolocationStatus = () => {
    if (!isGeolocationAvailable) {
      return <div>{t('common:form.error.geolocation')}</div>;
    }

    if (!isGeolocationEnabled) {
      return <div>{t('common:form.error.location')}</div>;
    }

    return null;
  };

  return (
    <>
      <Logo rootStyle={{ padding: 20 }} />
      <Row style={styles.root}>
        <Col>
          <Box paddingTop={4} paddingX={8}>
            <Title level={4} className="text-center">
              {t('welcome:shareLocation')}
            </Title>
          </Box>
        </Col>
        <Col>
          <Box marginTop={41} marginBottom={50}>
            <LocationSvg width={172} height={205} />
          </Box>
        </Col>
        <Col>
          <Box marginBottom={24}>
            <Button
              style={styles.btn}
              size="large"
              type="primary"
              onClick={onYes}
              disabled={!coords}
            >
              {t('common:button.yes')}
            </Button>
          </Box>
          <Box marginBottom={36}>
            <Button style={styles.btn} size="large" onClick={onNext}>
              {t('common:button.no')}
            </Button>
          </Box>
        </Col>
        {renderGeolocationStatus()}
      </Row>
    </>
  );
};

MobileLocationAccess.propTypes = geoPropTypes;

export default geolocated({
  positionOptions: {
    enableHighAccuracy: false,
  },
  suppressLocationOnMount: false,
  isOptimisticGeolocationEnabled: true,
})(MobileLocationAccess);
